import React, {useEffect} from "react";
import styled, { withTheme } from "styled-components";
// import {motion} from 'framer-motion';
// Imported Components
import ExchangeTopGames from "./exchangepage-topgames/index";
import HomeExchange, { AppWrapper } from "../../home-exchange/index";
// import { Outlet } from "react-router-dom";
import { withAppContext } from "src/store/initAppContext";
import config from "src/config/config";
import { theme } from "styled-tools";
import { useParams } from "react-router-dom";
import SEOContents from "src/components/SEOContents";
import { Helmet } from "react-helmet";
import { CRMPageVisit } from "src/util/crmUtil";

const structuredData = (domain) => {
  const data = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement":
          [
              {
                  "@type": "ListItem",
                  "item": `${domain}`,
                  "name": "Homepage",
                  "position": 1
              },
              {
                  "@type": "ListItem",
                  "item": `${domain}/betting-exchange/`,
                  "name": "Bet on Sports",
                  "position": 2
              }
             ],
      "name": "Breadcrumbs"
  }
  return JSON.stringify(data);
}

const ExchangePage = (props) => {
  const {
    app: { brandId },
    theme,
  } = props;

  const {id} = useParams();
  let domainURL = '';

  if (brandId === 31) {
    domainURL = 'https://www.yolo247.co';
  }
  else if (brandId === 32) {
    domainURL = 'https://www.yolo247.club';
  }
  else if (brandId === 33) {
    domainURL = 'https://www.baaziadda.com';
  }
  else if (brandId === 34) {
    domainURL = 'https://www.betdaily.club';
  }
  else if (brandId === 39) {
    domainURL = 'https://www.fomo7.com';
} else if (brandId === 40) {
  domainURL = 'https://www.fomo7.xyz';
}

  useEffect(()=> {
    //for CRM
    CRMPageVisit();
  },[])

  return (
    <ExchangeWrapper>
      {!id &&
        <>
          {/* <Helmet>
            <script type="application/ld+json">
              {structuredData(domainURL)}

            </script>
          </Helmet> */}
          <SEOContents pageName={"sportsBetting"} />

        </>
      }

      {id && id==='online-cricket-betting' && 
      <>
        <SEOContents pageName={"onlineCricketSattaBazar"}/>
      </>
      }

      {id && id==='football-betting' && 
      <>
        <SEOContents pageName={"footballBetting"}/>
      </>
      }

      {id && id==='tennis-betting' && 
      <>
        <SEOContents pageName={"tennisBetting"}/>
      </>
      }

      {id && id==='volleyball-betting' && 
      <>
        <SEOContents pageName={"volleyballBetting"}/>
      </>
      }




      
      <ExchangeTopGames />
      <HomeExchange />
      {/* <Outlet/> */}
    </ExchangeWrapper>
    // </motion.div>
  );
};

export default withTheme(withAppContext(ExchangePage));

/*! styled-components */
const ExchangeWrapper = styled.div`
  width: 100%;
  height: auto;
  background-image: url(${config.imageV3Host}/static/cashsite/${theme("image.homeExchangeBgImage")});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  background-attachment: fixed;

  ${AppWrapper} {
    min-height: 100vh;
  }
`;
