import { useEffect, useState } from "react";
import FooterContainer from "src/components/footer/footer";
// import ABOUTusSEO from "src/components/SEOComponents/aboutusSEO";
import SEOContents from "src/components/SEOContents";
import { withAppContext } from "src/store/initAppContext";
import styled, { withTheme } from "styled-components";
import { Helmet } from "react-helmet";
import AboutUs39 from "./aboutUs39";

const structuredData = (brandId, sitename) => {
  let domainURL = 'https://www.yolo247.co';

  if (brandId === 32) {
    domainURL = 'https://www.yolo247.club';
  }
  else if (brandId === 33) {
    domainURL = 'https://www.betdaily.com';
  }
  else if (brandId === 34) {
    domainURL = 'https://www.betdaily.club';
  } else if (brandId === 39) {
    domainURL = 'https://www.fomo7.com';
  } else if (brandId === 40) {
    domainURL = 'https://www.fomo7.xyz';
  }


  const data = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement":
      [
        {
          "@type": "ListItem",
          "item": `${domainURL}`,
          "name": "Homepage",
          "position": 1
        },
        {
          "@type": "ListItem",
          "item": `${domainURL}/aboutus`,
          "name": `About ${sitename}`,
          "position": 2
        }
      ],
    "name": "Breadcrumbs"
  }

  return JSON.stringify(data)
}

function AboutUs(props: any) {

  const { app: {
    brandId
  }, theme } = props

  const domain = brandId === 31 || brandId === 32 ? 'Yolo247' : (brandId == 39 || brandId == 40) ? 'Fomo7' : brandId === 33 ? 'Baazi Adda' : 'Betdaily';

  const [sitename, setsitename] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)

    switch (brandId) {
      case 31:
        setsitename('yolo247')
        break;
      case 32:
        setsitename('yolo247')
        break;
      case 33:
        setsitename('Baazi Adda')
        break;
      case 34:
        setsitename('betdaily')
        break;
      case 39:
        setsitename('fomo7')
        break;
        case 40:
          setsitename('fomo7')
          break;
      default:
        setsitename('')
        break;
    }
  }, [])

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {
            structuredData(brandId, sitename)
          }
        </script>
      </Helmet>
      <SEOContents pageName={"aboutus"} />
      {(brandId === 39 || brandId === 40) ? <ContentWrapper><AboutUs39 /></ContentWrapper> : (
        <ContentWrapper>
          <QuoteWrapper>
            <h1>" I HAVE WAYS OF MAKING MONEY THAT YOU KNOW NOTHING OF "<br /><br /> - John D Rockfeller</h1>
          </QuoteWrapper>
          <p className="info">
            Welcome to {sitename} - the world’s biggest betting exchange. A one stop shop for all sports betting and leisure
            gambling needs. The live casino is one of a kind complete with a spectacular range of games such as Baccarat,
            Teen Patti, Roulette, Andar- Bahar, Poker, Blackjack etc. These games are all conducted by live dealers instead
            of bots. Sports betting on {sitename}  includes the likes of all major events under Cricket, Tennis, Football, Horse
            racing complete with the maximum fancy market options available
          </p>

          <p>
            The safe and private environment and the integrity of our products are the fundamental drivers of the {sitename} online
            gaming experience. We have the most advanced security measures available and are continually auditing our
            games and processes to ensure a totally safe and fair internet gambling experience. We keep all of your information
            confidential, and we will never share it or sell it to third parties, except in accordance with our Privacy Policy.<br /><br />
            With 24 hour live customer support available 7 days per week, our highly trained and friendly staff will ensure that
            any queries are dealt with and resolved quickly, politely, and efficiently.<br /><br />
            Our mission is to provide the best online gambling experience for responsible players, please feel free to contact us
            by phone or email with your comments or suggestions<br /><br />
            Sign Up now and explore the {sitename} Experience.
          </p>


          <h2>About {domain} | Your Ultimate Destination for Online Gaming</h2>
          <p>Welcome to {domain}, one of the finest online gaming destinations. We are a group of gaming enthusiasts who enjoy offering some of the top casino games and sports betting.</p>
          <p>At {domain}, every bettor deserves a fair, secure, and exciting platform to place bets and enjoy their favorite games.</p>

          <h2>{domain} Betting</h2>
          <p>We're passionate about casino and sports betting. We offer many betting markets and options to cater to every user's needs. Whether you're a casino, cricket, football, or tennis fan, we've got you covered with our extensive betting options. You can also play exciting games like Aviator, Slots, Blackjack, Roulette, etc.</p>
          <p>We are determined to meet the expectations of every online casino and sports betting player. The platform offers Popular Casino Games, Instant games, Live Casino, Slot and Bingo, Hot Games, Sportsbook, Exchange, and Virtual Sports.</p>

          <h2>Values & Mission</h2>
          <p>{domain} believes in fair play and transparency. Our mission is to provide all users with a safe, secure, and reliable gaming experience. Our values are deeply rooted in our commitment to providing responsible gaming with integrity and 24*7 excellent customer service.</p>

          <h3>Responsible Gaming</h3>
          <p>We believe in socially responsible gaming. Therefore, we have strict measures in place to promote responsible gaming. We offer our players the tools to bet responsibly, including deposit caps, self-exclusion, and deposit limitations.</p>

          <h3>Integrity</h3>
          <p>We take transparency and integrity seriously by maintaining high fairness and impartiality. Our games undergo stringent testing and auditing procedures to ensure they adhere to the most outstanding standards of fairness and are devoid of prejudice. We safeguard the information and transactions of our bettors using the latest technology security methods.</p>

          <h3>Excellent Customer Service</h3>
          <p>Our players come first for us. With round-the-clock customer support, we are committed to giving customers the best support possible. We swiftly handle all player queries and problems and find solutions in a time-bound manner. In short, our goal is to create a world-class gaming experience for our players.</p>

          <h2>What Makes {domain} Betting Different from Other Betting Platforms?</h2>
          <p>{domain} stands out from other betting websites due to our commitment to providing customers with a world-class gaming experience. Our dedication to transparency and customer satisfaction sets us apart from other betting platforms.</p>

          <h3>Safe and Secure Platform</h3>
          <p>Providing a safe and secure experience is one of the objectives of {domain}. With advanced technology, players' personal and financial information cannot be accessed by a third-party website or app.</p>

          <h3>Wide Range of Betting Options</h3>
          <p>Various betting options are available for most games on our platform. With each player having different tastes, the many betting options suit everyone.</p>

          <h3>Competitive Odds and High Payouts</h3>
          <p>At {domain}, we offer some of the most competitive odds in the industry, giving our bettors the best possible chance of maximizing their winnings. We also offer high payouts, meaning our customers can win big even with small bets.</p>

          <h3>24*7 Customer Support</h3>
          <p>We are there for our players 24*7 and seven days a week. Our trained customer service representatives have expert platform knowledge and can assist with your queries.</p>

          <h3>{domain} Blog</h3>
          <p>We offer regularly updated tips, strategies, guides, and blogs to help you master your betting skills. Whether you prefer casino or sports betting, we provide valuable insights for all sports and casino games.</p>

          <h3>Simple and Fast Registration Process</h3>
          <p>Joining {domain} is a simple process. Providing a username, password, and mobile number takes only a few seconds. Meanwhile, the verification of the mobile number is an instant process.</p>

          <h3>Exciting Promotions and Bonuses</h3>
          <p>We reward customers with bonuses and promotions constantly. A Welcome bonus, Cashback deposit bonuses, and Referral bonuses are some of the rewards {domain} offers to players. Also, during a major sports event, special prizes and bonuses are up for grabs.</p>

          <h3>Payment Methods</h3>
          <p>At {domain}, we understand the importance of hassle-free payments. That's why we have made the deposit and withdrawal process smooth. Our unified payment system supports all popular payment methods and modes.</p>

          <h4>You can Bet In Indian Rupees</h4>
          <p>We understand the value of seamless transactions when it comes to online betting. With our hassle-free payment options, you can make payments in Indian Rupees - Netbanking, Debit/Credit cards, UPI, etc.</p>

          <h2>Benefits of using {domain} Online Betting</h2>
          <p>{domain} offers a wide range of games and betting options for bettors. You can get the finest odds and exciting betting markets on {domain} regardless of whether you like betting on casino games, cricket, football, tennis, etc. The advantages of using {domain} include the following:</p>

          <h3>Easy Signup and Secure Transactions</h3>
          <p>Before you can begin playing, register an account with your personal details on {domain}. Following registration, you may deposit to start gaming. The platform uses advanced encryption and security protocols to protect personal and financial information.</p>

          <h3>Generous Bonuses and Promotions</h3>
          <p>{domain} rewards its new and existing customers with generous bonuses and promotions. You can get a welcome bonus as a new player on your first deposit. You can get cashback, refill bonuses, and more during major tournaments like IPL, PSL, BBL, etc.</p>

          <h3>Variety of Games and Betting Options</h3>
          <p>{domain} offers a variety of games and betting options for all kinds of players. Several online casino games are available, including poker, slot games, and more. If you like sports, you may wager on cricket, football, kabaddi, tennis, and more. You can bet on live events and in-play markets with competitive odds and payouts.</p>

          <h3>Customer Support and Responsible Gaming</h3>
          <p>{domain} has a devoted customer service staff available all the time. Contact us via phone, email, telegram, or live chat. The platform is a flag bearer for responsible gaming and provides the necessary help to play your favorite games. You can set deposit limits, time limits, self-exclusion periods, etc.</p>
          <h3>Offers & Promotions</h3>
          <p>At {domain}, every bettor deserves the best online betting experience. To assist you in winning big, we provide multiple bonuses and promotions, such as:</p>
          <h4>Welcome Bonus:</h4>
          <p>Start your betting journey with a bang by taking advantage of our exciting welcome bonus.</p>
          <h4>Seasonal and Personal Promotions:</h4>
          <p>We offer exclusive promotions tailored to your needs and preferences.</p>
          <h4>Top Parlays Selections & More:</h4>
          <p>Enjoy some of the best parlay selections and other online betting options.</p>
          <h4>Loyalty Programs:</h4>
          <p>We also offer unique loyalty programs that give you exclusive rewards and benefits.</p>
          <h2>Benefits of Choosing {domain}</h2>
          <p>We take massive pride in our unique online casino games and sports betting. The games are crafted with the players in mind. It not only offers rewards but a delightful and entertaining experience.</p>
          <p>At {domain}, we strive to innovate constantly and conduct regular analyses to identify potential opportunities to make online betting even more enjoyable. Our customers choose {domain} for their casino and sports betting needs for many reasons. Here are some reasons:</p>
          <h3>Live Betting</h3>
          <p>Live betting is one of the most exciting features on {domain}. Place bets as the game progresses and earn opportunities to maximize your rewards. Stay on the game and enjoy a top betting experience with {domain}'s live betting feature.</p>
          <h3>Cash-out Options</h3>
          <p>You don't have to wait until the game is over to collect your wins, thanks to {domain}'s instant withdrawal feature. It makes the gaming experience all the more enjoyable.</p>
          <h3>Stay Updated with Live Scores</h3>
          <p>You can keep track of the up-to-date scores of your favorite cricket and football matches with {domain}'s exclusive live score feature. Our platform features a dedicated scorecard for each match and a special section highlighting the key moments.</p>
          <h3>Get The Best Betting Odds</h3>
          <p>Every betting experience should be rewarding and enjoyable. We offer the best betting odds for all casino and sports betting. With expert insights, you can take your betting to the next level.</p>
          <h3>Withdraw Anytime</h3>
          <p>At {domain}, the journey to winning is incomplete with a seamless withdrawal experience. That's why we have one of the best withdrawal systems in the online gaming industry. Our withdrawal process is swift and transparent.</p>
          <h2>Top Games Available On {domain}</h2>
          <p>{domain} is the one-stop destination for all your online betting needs. You can enjoy all types of casino games and sports betting on {domain}. Here is the list of the games available on the platform:</p>
          <ul>
            <li>Live Casino: Chat with dealers and fellow casino players in live casino games. You can play live versions of various games, including Baccarat, Poker, Live Roulette, and Blackjack.</li>
            <li>Slots: Spin the reels of hundreds of slot games with different themes, features, and jackpots. You can get classic slots, video slots, progressive slots, and more.</li>
            <li>Table Games: Play traditional casino games like Roulette, Blackjack, Baccarat, Poker, etc.</li>
            <li>Sports: {domain} is also an excellent platform for sports betting enthusiasts. You can bet on various sports events like cricket, football, kabaddi, and tennis.</li>
          </ul>
          <h2>How to Start Your Gaming Journey on {domain}?</h2>
          <p>{domain} is not just a gaming platform but a community of bettors who share a passion for gaming. Joining {domain} is very easy and fast.</p>
          <ol>
            <li>Visit the official {domain} website or download the {domain} app.</li>
            <li>Register an account on {domain} by providing a username, password, and mobile number.</li>
            <li>Verify your {domain} account by entering the OTP sent to your mobile number.</li>
          </ol>
          <p>Congratulations! You are now part of the {domain} family.</p>
          <h2>Final Thoughts on {domain} Online Betting</h2>
          <p>{domain} has something for every gamer. You can also earn rewards and bonuses on the platform. Join {domain} today and discover a new way of gaming that is fun, social, and rewarding.</p>
        </ContentWrapper>
      )}
      {/* <ABOUTusSEO/> */}
      <FooterContainer />

    </>
  )
}

export default withTheme(withAppContext(AboutUs));

const ContentWrapper = styled.div`
	max-width: 800px;
	margin: 0 auto;
	font-size: 12px;
	padding: 2em 1.25em;

    p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: #fff;
		margin-top: 1.23em;
	}

    .info{
        color: #EEBC15;
    }

	h1 {
		font-size: 25px;
        color: beige;
	}

	h2 {
		font-size: 16px;
	}

	h3 {
		font-size: 14px;
	}

	ul ul,
	ol {
		margin: 1em 0;
	}

	li {
		margin: 0.75em 0;
        color: #fff;
	}

	a {
		color: #EEBC15;
		text-decoration: underline;
	}

  .aboutHeading {
    font-size: 18px;
    color: #f4a322;
  }
`;

const QuoteWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    white-space: nowrap;
    text-align: center;
`;